@tailwind base;
@tailwind components;
@tailwind utilities;


.main-layout {

    height: 100vh;
    background: linear-gradient(to bottom, #0076ad, #4f89cb, #4884b5);

  }
  

.bubs-dynamic{
    @apply relative grow shrink pt-5
    dark:bg-bubble-bg-d w-auto ;
}



.bubs-group-kpi{
    background: linear-gradient(0.25turn, #60c7f7, #5b9fed, #5b9fed, #60c7f7);
    @apply  p-2  shadow-2xl text-xl rounded-lg;
}
    
.bubs-dash-kpi{
    @layer surface-0;
    cursor: pointer;
    @apply  p-3 rounded-xl  text-lg;
   
}



/* Prime react Datatable CSS definitions & customizations */


/* Table Body CSS */

.p-datatable .p-datatable-tbody > tr {
    @apply relative grow shrink bg-bubble-bg-l
    dark:bg-bubble-bg-d rounded-3xl max-w-sm text-main-txt-l dark:text-main-txt-d
}

/* Table Head CSS */

.p-datatable .p-datatable-thead {
    @apply grow shrink bg-bubble-bg-l sticky
    dark:bg-bubble-bg-d rounded-3xl  text-main-txt-l dark:text-main-txt-d
}

/* Table Header CSS */

.p-datatable .p-datatable-header {
    @apply relative grow shrink bg-slate-300 dark:bg-bubble-bg-d
     text-main-txt-l dark:text-main-txt-d
}

.p-sortable-column.p-resizable-column.p-highlight.p-reorderable-column {
    @apply bg-bubble-bg-l dark:bg-bubble-bg-d
}

.p-sortable-column.p-resizable-column.p-highlight.p-reorderable-column :hover {
    @apply bg-inherit dark:text-blue-500 text-main-txt-l
}

.p-sortable-column.p-resizable-column.p-reorderable-column {
    @apply bg-bubble-bg-l dark:bg-bubble-bg-d 
}

.p-sortable-column.p-resizable-column.p-reorderable-column :hover{
    @apply bg-inherit  dark:text-blue-500 text-main-txt-l
}

/* Sort Arrow in DataTable */

.p-sortable-column.p-resizable-column.p-reorderable-column :hover .pi-sort-alt {
    @apply block text-blue-900 dark:text-blue-500
}

.pi-sort-alt {
    @apply hidden
}


/* Filter ICON in Table Head */

.pi.pi-filter-icon.pi-filter {
    @apply text-main-txt-l dark:text-blue-500 
}

/* Circle highlighter for the Filter Indicator */
.p-column-filter-menu-button.p-column-filter-menu-button-active {
    @apply bg-sky-200 dark:bg-bubble-bg-l
}

.p-sortable-column.p-resizable-column.p-reorderable-column :hover .pi.pi-filter-icon.pi-filter  {
    @apply text-blue-900 dark:text-blue-500
}

.p-sortable-column.p-resizable-column.p-reorderable-column :hover .p-column-title {
    @apply text-blue-900 dark:text-blue-500
}

/* Column Title  CSS */

.p-column-title {
    @apply text-main-txt-l dark:text-blue-500 text-xs
}

.p-paginator.p-component.p-paginator-bottom {
    @apply bg-bubble-bg-l dark:bg-bubble-bg-d 
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    @apply dark:text-main-txt-d text-blue-500
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon :hover {
    @apply dark:text-main-txt-d text-blue-500
}


.p-datatable .p-sortable-column .p-sortable-column-badge {
    @apply text-blue-500
}


.p-column-filter-overlay {
    max-width: 255px;
    max-height: auto;
}

.p-button {
    color: #6366F1;
}

.p-button-label{
    font-weight: normal;
    font-size: 1rem;
}
.p-selectbutton .p-button{
    padding: 0.45rem 0.5rem;
    @apply  bg-white
    dark:bg-bubble-bg-d dark:border-white dark:text-white text-black
}

.p-selectbutton .p-button.p-highlight{
    @apply bg-button-selection-highlight
}


/* Multi Select CSS customization */

/* .p-multiselect {
    @apply bg-bubble-bg-l
    dark:bg-bubble-bg-d
} */


.p-multiselect .p-multiselect-label {
    padding: 0.6rem 0.5rem;
}

.chartFilter .p-multiselect .p-multiselect-label {
    padding: 0.5rem 0.4rem;
}


/* Multi-Select filter Text field CSS */
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container  .p-inputtext{
    @apply p-0.5 bg-bubble-bg-l dark:bg-bubble-bg-d dark:text-white
}

.p-multiselect-panel .p-multiselect-items {
    @apply p-0 bg-bubble-bg-l
  }

.p-multiselect[id="columnPicker"] {
    padding: 0.1rem 0.1rem;
    @apply bg-bubble-bg-l
    dark:bg-bubble-bg-d
}


.p-multiselect-panel .p-multiselect-header {
    background: #6a90b19a;
    @apply p-2 dark:bg-bubble-bg-d bg-white
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #2b4661;
    @apply bg-white font-semibold

  }

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    @apply p-2 bg-white dark:bg-bubble-bg-d dark:text-white
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container  .p-inputtext {
    @apply bg-white  dark:bg-bubble-bg-d
}

/* Overlay Panel CSS for Cross filter selection */


.p-overlaypanel{
    @apply  bg-header-bg top-28

}
.p-overlaypanel .p-overlaypanel-content {
    @apply p-2.5
}


/* CSS for Porifle Initials Avatar */
.p-avatar{
    width: 2rem;
    height: 2rem;
}

/* Prime react Datatable CSS definitions & customizations - ENDS*/


/* Custom Text font*/

.text-kpi-group-header {
    @apply text-white font-bold
}

.text-kpi-header {
    color: var(--surface-600)!important;
    font-weight: 600
}

.text-kpi {    
    color: var(--surface-500)!important;
    font-weight: 200

    
}

.text-table-info {

    color: var(--surface-600)!important;
    font-weight: 200;
    @apply text-sm font-normal
    
}

  /* Prime react Accordion CSS customizations */

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }


  /* Prime react tags*/

  .p-tag.p-tag-danger {
 
    @apply bg-red-400
  }


  .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 30%;
    height: 2rem;
}

.p-button.p-button-icon-only {
    width: 2rem;
    /* padding: 0.75rem 0; */
}